import React from 'react'

function IntroText() {

return(
    <div class="header-middle-contaier">
        <h2>Full Stack Web Developer</h2>
    <h1><span class="cat">Catherine</span></h1>
    <h1 className ='last-name'><span>Radin-Schneider</span></h1>
<div class="please-work">
    
    <p>Creating innovative, responsive UI/UX designs and coding web applications is where my passion lies.
    Scroll to learn more!</p>
    </div>

    
</div>
)
}
export default IntroText