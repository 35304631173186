import React from 'react'

function Footer() {

return(
    <div class="footer-container">
        
            <p>Designed & built by Catherine Radin-Schneider 2021 </p>
    </div>
)
}
export default Footer;